// IMPORTS
import React, { useState, useEffect } from "react";
import { useMoralisWeb3Api } from "react-moralis";
import {
  connectToWallet,
  connectToContract,
  createCSV,
  takeSnapshot,
  readSnapshot,
  airdropTickets,
} from "../helpers/methods";
import ticketzContract from "../truffle/Ticketz.json";
import Blocked from "../components/Blocked";
import All from "./All";
import Single from "./Single";
import { ganache } from "../helpers/variables";
// COMPONENT
const Dashboard = () => {
  // STATE
  const [state, setState] = useState({
    address: null,
    page: "All",
    owners: null,
  });
  const { address } = state;
  const Web3API = useMoralisWeb3Api();
  // LIFE CYCLE
  useEffect(() => {
    const connect = async () => {
      const userAddress = await connectToWallet();
      setState((_state) => {
        return { ..._state, address: userAddress };
      });
    };
    connect();
    window.ethereum.on("accountsChanged", (accounts) => {
      setState((_state) => {
        return { ..._state, address: accounts[0] };
      });
    });
  }, [address, setState]);
  // BUTTONS
  const buttons = {
    pages: [
      "All",
      "Attendee",
      "VIP",
      "Ethereum Towers",
      "Crypto Holdem",
      "NDAX",
    ],
    methods: [
      {
        name: "Take Snapshot",
        method: async () => {
          const { success } = await takeSnapshot(Web3API);
          console.log("TAKE OWNERS SNAPSHOT: ", success);
        },
      },
      {
        name: "Read Last Snapshot",
        method: async () => {
          const snapshot = await readSnapshot("last");
          setState({
            ...state,
            page: "Snapshot",
            owners: JSON.stringify(snapshot.owners),
          });
        },
      },
      {
        name: "Create CSV",
        method: () => {
          createCSV();
        },
      },
      {
        name: "Airdrop Tickets",
        method: async () => {
          const response = await airdropTickets();
          console.log("response: ", response);
        },
      },
      {
        name: "Sign Out",
        method: () => {
          setState((_state) => {
            return { ..._state, address: null };
          });
        },
      },
    ],
    ticketz: [
      {
        name: "Set URI",
        method: async () => {
          const contract = await connectToContract(
            ticketzContract.address,
            ticketzContract.abi
          );
          const newURI = "https://bob.com/tickets/";
          await contract.setURI(newURI);
        },
      },
      {
        name: "Read URI",
        method: async () => {
          const contract = await connectToContract(
            ticketzContract.address,
            ticketzContract.abi
          );
          // console.log("contract: ", contract);
          const uri = await contract.uri(0);
          console.log("URI: ", uri);
        },
      },
    ],
    shoez: [],
  };
  // PAGES
  const pages = () => {
    switch (state.page) {
      case "All":
        return <All />;
      case "Attendee":
        return <Single id={0} />;
      case "VIP":
        return <Single id={1} />;
      case "Ethereum Towers":
        return <Single id={2} />;
      case "Crypto Holdem":
        return <Single id={3} />;
      case "NDAX":
        return <Single id={4} />;
      case "Snapshot":
        return (
          <div className="dashboardSnapshot">
            {state.owners.split(",").map((owner, index) => {
              const title = owner
                .replace("{", "")
                .replace('"', "")
                .replace('":', " owns ")
                .replace("}", "");
              return (
                <p key={index} className="dashboardSnapshotOwner">
                  {title}
                </p>
              );
            })}
          </div>
        );
      default:
        return <Blocked props={{ reason: "404" }} />;
    }
  };
  // RENDER
  // return address === process.env.REACT_APP_USER_ADDRESS ? (
  return (
    <div className="dashboardContainer">
      {/* DASHBOARD LEFT */}
      <div className="dashboard" style={{ left: "0rem" }}>
        <div className="dashboardButtons">
          <p className="dashboardButtonsTitle">Pages</p>
          {buttons.pages.map((button, index) => {
            return (
              <button
                key={index}
                className={
                  state.page === button
                    ? "dashboardButtonSelected"
                    : "dashboardButton"
                }
                style={{
                  height: `calc(100% / ${buttons.pages.length})`,
                }}
                onClick={() => {
                  setState({ ...state, page: button });
                }}
              >
                {button}
              </button>
            );
          })}
        </div>
        <div className="dashboardButtons">
          <p className="dashboardButtonsTitle">Methods</p>
          {buttons.methods.map(({ name, method }, index) => {
            return (
              <button
                key={index}
                className="dashboardButton"
                style={{
                  height: `calc(100% / ${buttons.methods.length})`,
                }}
                onClick={method}
              >
                {name}
              </button>
            );
          })}
        </div>
      </div>
      {/* DASHBOARD RIGHT */}
      <div className="dashboard" style={{ right: "0rem" }}>
        <div className="dashboardButtons">
          <p className="dashboardButtonsTitle">Ticketz</p>
          {buttons.ticketz.map(({ name, method }, index) => {
            return (
              <button
                key={index}
                className="dashboardButton"
                style={{
                  height: `calc(100% / ${buttons.ticketz.length})`,
                }}
                onClick={method}
              >
                {name}
              </button>
            );
          })}
        </div>
        <div className="dashboardButtons">
          <p className="dashboardButtonsTitle">Shoez</p>
          {buttons.shoez.map(({ name, method }, index) => {
            return (
              <button
                key={index}
                className="dashboardButton"
                style={{
                  height: `calc(100% / ${buttons.shoez.length})`,
                }}
                onClick={method}
              >
                {name}
              </button>
            );
          })}
        </div>
      </div>
      {/* PAGES */}
      <div className="dashboardPages">{pages()}</div>
    </div>
  );
  // : (
  //   <Blocked props={{ reason: "Wrong Address" }} />
  // );
};
// EXPORT
export default Dashboard;
