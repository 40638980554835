// IMPORTS
import "./styles/App.scss";
import React from "react";
import { MoralisProvider } from "react-moralis";
import ReactDOM from "react-dom";
import Blocked from "./components/Blocked";
import Dashboard from "./pages/Dashboard";
import All from "./pages/All";
import Create from "./pages/Create";
import Single from "./pages/Single";
// COMPONENT
const App = () => {
  // PAGES
  const pages = () => {
    switch (window.location.pathname) {
      case "/":
        return <Dashboard />;
      case "/all":
        return <All />;
      case "/create":
        return <Create />;
      case "/0":
        return <Single id={0} />; // ATTENDEE
      case "/1":
        return <Single id={1} />; // VIP
      case "/2":
        return <Single id={2} />; // ETHEREUM TOWERS
      case "/3":
        return <Single id={3} />; // CRYPTO HOLDEM
      case "/4":
        return <Single id={4} />; // NDAX
      default:
        return <Blocked props={{ reason: "404" }} />;
    }
  };
  // RENDER
  return (
    <div className="appContainer">
      {/* THROBBER */}
      <div className="throbberContainer">Loading . . .</div>
      {/* PAGES */}
      {pages()}
    </div>
  );
};
// INITIALIZE
ReactDOM.render(
  <MoralisProvider
    serverUrl={process.env.REACT_APP_MORALIS_SEVER_URL}
    appId={process.env.REACT_APP_MORALIS_APP_ID}
  >
    <App />
  </MoralisProvider>,
  document.getElementById("root")
);
