// IMPORTS
import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { RoundedBoxGeometry } from "three/examples/jsm/geometries/RoundedBoxGeometry";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import gsap from "gsap";
import colors from "../styles/modules/_exports.module.scss";
import { degrees, loadTexture } from "../helpers/methods";
import { tickets } from "../helpers/variables";
// COMPONENT
const Single = ({ id }) => {
  // STATE
  const mount = useRef(null);
  const loaded = useRef(false);
  // LIFE CYCLE
  useEffect(() => {
    // VARIABLES
    let height = window.innerHeight;
    let width = window.innerWidth;
    const raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();
    let clicked = false;
    let ticket = new THREE.Group();
    let dropdownFront = new THREE.Group();
    let dropdownBack = new THREE.Group();
    let video, videoTexture;
    let showDropdown = false;
    let timer = 0;
    // LOAD TEXTURES
    const face1 = loadTexture(tickets[id].image);
    const face2 = loadTexture(tickets[id].dropdown);
    Promise.all([face1, face2])
      .then((texture) => {
        // BOX
        const generateBox = () => {
          const boxGeometry = new RoundedBoxGeometry(17, 17, 0.6, 10, 1);
          const boxMaterial = new THREE.MeshBasicMaterial({
            color: new THREE.Color(tickets[id].color),
          });
          const boxMesh = new THREE.Mesh(boxGeometry, boxMaterial);
          ticket.add(boxMesh);
        };
        // FACE
        const generateFace = () => {
          const faceGeometry = new THREE.PlaneGeometry(16, 16);
          const faceMaterial = new THREE.MeshBasicMaterial({ map: texture[0] });
          const frontFace = new THREE.Mesh(faceGeometry, faceMaterial);
          frontFace.position.set(0, 0, 0.301);
          const backFace = new THREE.Mesh(faceGeometry, faceMaterial);
          backFace.position.set(0, 0, -0.301);
          backFace.rotateY(degrees(180));
          ticket.add(frontFace);
          ticket.add(backFace);
        };
        // BUTTON
        const generateButton = () => {
          const buttonGeometry = new THREE.PlaneGeometry(2, 2);
          const buttonMaterial = new THREE.MeshBasicMaterial({
            color: new THREE.Color(colors.pink),
            transparent: true,
            opacity: 0,
          });
          const playButtonFront = new THREE.Mesh(
            buttonGeometry,
            buttonMaterial
          );
          playButtonFront.name = "playButton";
          playButtonFront.position.set(6.1, 6.1, 0.303);
          const playButtonBack = new THREE.Mesh(buttonGeometry, buttonMaterial);
          playButtonBack.name = "playButton";
          playButtonBack.position.set(-6.1, 6.1, -0.303);
          playButtonBack.rotateY(degrees(180));
          ticket.add(playButtonFront);
          ticket.add(playButtonBack);
        };
        // DROPDOWN
        const generateDropdown = () => {
          // VIDEO
          video = document.getElementById("video");
          videoTexture = new THREE.VideoTexture(video);
          videoTexture.minFilter = THREE.LinearFilter;
          videoTexture.magFilter = THREE.LinearFilter;
          const videoGeometry = new THREE.PlaneGeometry(16, 8.5);
          const videoMaterial = new THREE.MeshBasicMaterial({
            map: videoTexture,
            side: THREE.FrontSide,
            toneMapped: false,
          });
          const videoMeshFront = new THREE.Mesh(videoGeometry, videoMaterial);
          const videoMeshBack = new THREE.Mesh(videoGeometry, videoMaterial);
          videoMeshFront.position.set(0, 0, 0.001);
          videoMeshBack.position.set(0, 0, 0.001);
          dropdownFront.add(videoMeshFront);
          dropdownBack.add(videoMeshBack);
          // UI
          const uiGeometry = new THREE.PlaneGeometry(16, 16);
          const uiMaterial = new THREE.MeshBasicMaterial({
            map: texture[1],
          });
          dropdownFront.add(new THREE.Mesh(uiGeometry, uiMaterial));
          dropdownBack.add(new THREE.Mesh(uiGeometry, uiMaterial));
          // DROP DOWN
          dropdownFront.position.set(0, 0, 0.302);
          dropdownFront.visible = false;
          dropdownBack.position.set(0, 0, -0.302);
          dropdownBack.rotateY(degrees(180));
          dropdownBack.visible = false;
          ticket.add(dropdownFront);
          ticket.add(dropdownBack);
        };
        generateBox();
        generateFace();
        generateButton();
        generateDropdown();
      })
      .then(() => {
        initialize();
      });
    // INITIALIZE
    const initialize = () => {
      // SCENE
      const scene = new THREE.Scene();
      // LIGHTS
      const ambientLight = new THREE.AmbientLight(colors.gray);
      scene.add(ambientLight);
      // CAMERA
      const camera = new THREE.PerspectiveCamera(
        75,
        width / height,
        0.1,
        10000
      );
      scene.add(camera);
      // RENDERER
      const renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
      });
      renderer.setSize(width, height);
      renderer.setPixelRatio(window.devicePixelRatio);
      if (!loaded.current) {
        loaded.current = true;
        mount.current.appendChild(renderer.domElement);
      } else {
        mount.current.removeChild(mount.current.lastChild);
        mount.current.appendChild(renderer.domElement);
      }
      // CONTROLS
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enablePan = false;
      controls.enableZoom = false;
      controls.maxPolarAngle = degrees(90);
      controls.minPolarAngle = degrees(90);
      camera.position.set(0, 0, 20);
      controls.update();
      // TICKET
      scene.add(ticket);
      // ANIMATIONS
      const spin = gsap.timeline({ paused: true });
      spin.fromTo(
        ticket.rotation,
        { y: degrees(0) },
        {
          y: degrees(-180),
          duration: 3,
          repeat: -1,
          ease: "linear",
        }
      );
      const spinWithVideo = gsap.timeline({
        paused: true,
        onComplete: () => {
          video.play();
          dropdownFront.visible = true;
          dropdownBack.visible = true;
        },
      });
      spinWithVideo.fromTo(
        ticket.rotation,
        { y: degrees(0) },
        {
          y: degrees(-180),
          duration: 3,
          ease: "linear",
        }
      );
      // HANDLE EVENTS
      const handleEvents = () => {
        controls.addEventListener("change", () => {
          timer = 0;
          spin.pause();
        });
        window.addEventListener("click", (event) => {
          clicked = true;
          pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
          pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
        });
        window.addEventListener("resize", () => {
          camera.aspect = window.innerWidth / window.innerHeight;
          camera.updateProjectionMatrix();
          renderer.setSize(window.innerWidth, window.innerHeight);
          renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        });
        window.addEventListener("orientationchange", () => {
          camera.aspect = window.innerWidth / window.innerHeight;
          camera.updateProjectionMatrix();
          renderer.setSize(window.innerWidth, window.innerHeight);
          renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        });
      };
      // LOOP
      const loop = () => {
        timer += 1;
        raycaster.setFromCamera(pointer, camera);
        const intersects = raycaster.intersectObjects(scene.children);
        if (clicked) {
          if (intersects.length > 0) {
            if (intersects[0].object?.name === "playButton") {
              clicked = false;
              showDropdown = !showDropdown;
              if (showDropdown) {
                spin.pause();
                video.play();
              } else {
                video.pause();
                spin.play();
              }
              dropdownFront.visible = showDropdown;
              dropdownBack.visible = showDropdown;
            }
          }
        }
        if (timer > 200) {
          !showDropdown && spin.paused() && spin.play();
        }
        videoTexture.update();
        renderer.render(scene, camera);
      };
      // START
      handleEvents();
      spin.play();
      renderer.setAnimationLoop(loop);
    };
  }, [id]);
  // RENDER
  return (
    <div ref={mount} className="singleContainer">
      <video
        id="video"
        // muted
        loop
        style={{ display: "none" }}
        src={require("../assets/videos/rockies.mp4")}
      />
    </div>
  );
};
// EXPORT
export default Single;
