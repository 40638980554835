// IMPORTS
import colors from "../../styles/modules/_exports.module.scss";
import React from "react";
import { FaPause } from "react-icons/fa";
// COMPONENT
const Dropdown = ({ props }) => {
  // STATE
  const { ticketRef, ticketType } = props;
  const Attendee = ticketType === "attendee";
  const VIP = ticketType === "vip";
  const EthereumTowers = ticketType === "ethereum-towers";
  const CryptoHoldem = ticketType === "crypto-holdem";
  const NDAX = ticketType === "ndax";
  // RENDER
  return (
    <div
      ref={ticketRef}
      className="ticketContainer"
      style={{
        background: EthereumTowers
          ? colors.white
          : CryptoHoldem && colors.black,
      }}
    >
      {/* HEADER */}
      <div className="dropdownHeader">
        <img
          className="dropdownLogo"
          alt="Logo"
          src={
            EthereumTowers
              ? require("../../assets/logos/nft-gathering-black.png")
              : CryptoHoldem || NDAX
              ? require("../../assets/logos/nft-gathering-white.png")
              : require("../../assets/logos/nft-gathering.png")
          }
        />
        <p
          className="dropdownPauseButton"
          style={{ color: EthereumTowers && "black" }}
        >
          <FaPause
            className="dropdownPauseIcon"
            style={{ color: EthereumTowers && "black" }}
          />
          Pause
        </p>
      </div>
      {/* VIDEO */}
      <div className="dropdownVideo" />
      {/* FOOTER */}
      <div className="dropdownFooter">
        <p
          className="dropdownText"
          style={{ color: EthereumTowers && "black" }}
        >
          Join us April 20 and 21
        </p>
        <p
          className="dropdownText"
          style={{ color: EthereumTowers && "black" }}
        >
          In beautiful Banff, Alberta, Canada
        </p>
      </div>
    </div>
  );
};
// EXPORT
export default Dropdown;
