// IMPORTS
import colors from "../styles/modules/_exports.module.scss";
// TICKETS
const tickets = [
  {
    name: "Attendee",
    color: colors.yellow,
    image: require("../assets/tickets/attendee.png"),
    dropdown: require("../assets/dropdowns/attendee.png"),
  },
  {
    name: "VIP",
    color: colors.green,
    image: require("../assets/tickets/vip.png"),
    dropdown: require("../assets/dropdowns/vip.png"),
  },
  {
    name: "Ethereum Towers",
    color: colors.black,
    image: require("../assets/tickets/ethereum-towers.png"),
    dropdown: require("../assets/dropdowns/ethereum-towers.png"),
  },
  {
    name: "Crypto Holdem",
    color: colors.blue,
    image: require("../assets/tickets/crypto-holdem.png"),
    dropdown: require("../assets/dropdowns/crypto-holdem.png"),
  },
  {
    name: "NDAX",
    color: colors.lightBlue,
    image: require("../assets/tickets/ndax.png"),
    dropdown: require("../assets/dropdowns/ndax.png"),
  },
];
// GANACHE
const ganache = "0xf9d12F3d23005aAc2a52E72C0ca989fE7f08599f";
// EXPORTS
export { tickets, ganache };
