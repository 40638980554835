// IMPORTS
import React, { createRef, useEffect } from "react";
import { exportComponentAsPNG } from "react-component-export-image";
// import { connectWallet } from "../helpers/methods";
import Attendee from "../components/tickets/Attendee";
import Vip from "../components/tickets/Vip";
import EthereumTowers from "../components/tickets/EthereumTowers";
import CryptoHoldem from "../components/tickets/CryptoHoldem";
import Blocked from "../components/Blocked";
import NDAX from "../components/tickets/NDAX";
import Dropdown from "../components/tickets/Dropdown";
// COMPONENT
const Create = ({ props }) => {
  // STATE
  const { state, setState } = props;
  const { address } = state;
  const ticketRef = createRef();
  // LIFE CYCLE
  useEffect(() => {
    // const connect = async () => {
    //   await connectWallet().then((userAddress) => {
    //     setState((_state) => {
    //       return { ..._state, address: userAddress };
    //     });
    //   });
    // };
    // connect();
    // window.ethereum.on("accountsChanged", () => connect());
  }, [setState]);
  // RENDER
  return address === process.env.REACT_APP_USER_ADDRESS ? (
    <div className="createContainer">
      {/* SAMPLE */}
      <img
        className="createSample1"
        alt="Ticket"
        // src={require("../assets/tickets/attendee.png")}
        // src={require("../assets/tickets/vip.png")}
        // src={require("../assets/tickets/ethereum-towers.png")}
        // src={require("../assets/tickets/crypto-holdem.png")}
        src={require("../assets/tickets/ndax.png")}
        // src={require("../assets/tickets/dropdown.jpeg")}
      />
      <img
        className="createSample2"
        alt="Ticket"
        // src={require("../assets/tickets/attendee.png")}
        // src={require("../assets/tickets/vip.png")}
        // src={require("../assets/tickets/ethereum-towers.png")}
        // src={require("../assets/tickets/crypto-holdem.png")}
        src={require("../assets/tickets/ndax.png")}
        // src={require("../assets/tickets/dropdown.jpeg")}
      />
      {/* <Attendee props={{ ticketRef }} /> */}
      {/* <Vip props={{ ticketRef }} /> */}
      {/* <EthereumTowers props={{ ticketRef }} /> */}
      {/* <CryptoHoldem props={{ ticketRef }} /> */}
      {/* <NDAX props={{ ticketRef }} /> */}
      {/* <Dropdown props={{ ticketRef, ticketType: "attendee" }} /> */}
      <Dropdown props={{ ticketRef, ticketType: "vip" }} />
      {/* <Dropdown props={{ ticketRef, ticketType: "ethereum-towers" }} /> */}
      {/* <Dropdown props={{ ticketRef, ticketType: "crypto-holdem" }} /> */}
      {/* <Dropdown props={{ ticketRef, ticketType: "ndax" }} /> */}

      {/* SAVE BUTTON */}
      <button
        className="createSaveButton"
        onClick={() => {
          exportComponentAsPNG(ticketRef);
        }}
      >
        Save
      </button>
    </div>
  ) : (
    <Blocked />
  );
};
// EXPORT
export default Create;
