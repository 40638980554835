// IMPORTS
import * as THREE from "three";
import Moralis from "moralis";
import { ethers } from "ethers";
import shoezContract from "../contracts/shoez";
import ticketzContract from "../contracts/ticketz";
// import ticketzContract from "../truffle/Ticketz.json";
import { ganache } from "./variables";
// DEGREES
const degrees = (degree) => (degree * Math.PI) / 180;
// LOAD TEXTURE
const loadTexture = (image) =>
  new Promise((resolve, reject) => {
    const loadDone = (texture) => resolve(texture);
    const loader = new THREE.TextureLoader();
    loader.load(image, loadDone);
  });
// EMIT ERROR
const emitError = (message, error) => {
  console.log(`${message}:: `, error);
};
// FETCH API
const fetchAPI = async (endpoint, data) => {
  const request = data
    ? new Request(`http://localhost:3001/${endpoint}`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        body: JSON.stringify(data),
      })
    : new Request(endpoint, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      });
  return await fetch(request)
    .then((response) => response.json())
    .then((response) => response)
    .catch((error) => emitError("FETCH API ERROR: ", error));
};
// CONNECT TO WALLET
const connectToWallet = async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const accounts = await provider.send("eth_requestAccounts", []);
    return accounts[0];
  } catch (error) {
    if (error.code === 4001) {
      emitError("User Rejected Login");
    } else if (error.code === -32002) {
      emitError("Login Request Pending");
    }
    emitError("Connect To Wallet Failed", error);
  }
};
// CONNECT TO CONTRACT
const connectToContract = (address, abi) => {
  return new Promise(async (resolve, reject) => {
    try {
      // const network = "homestead";
      const network = "rinkeby";
      const provider = ethers.getDefaultProvider(network, {
        etherscan: process.env.REACT_APP_ETHERSCAN_KEY,
      });
      // const provider = new ethers.providers.JsonRpcProvider(
      //   "http://localhost:7545"
      // );
      const signer = provider.getSigner();
      // const contract = new ethers.Contract(address, abi, provider);
      const contract = new ethers.Contract(address, abi, signer);
      resolve(contract);
    } catch (error) {
      emitError("CONNECT CONTRACT ERROR", error);
      reject(error);
    }
  });
};
// GET OWNERS
const getOwners = async () => {
  let offset = 0;
  let totalNFTs = 0;
  let totalCalled = 0;
  let result = [];
  const getNFTOwners = async () => {
    const NFTOwners = await Moralis.Web3API.token.getNFTOwners({
      address: shoezContract.address,
      offset,
      limit: 500,
    });
    totalCalled += NFTOwners.result.length;
    result = [...result, ...NFTOwners.result];
    totalNFTs === 0 && (totalNFTs = NFTOwners.total);
    if (totalNFTs > totalCalled) {
      offset += 500;
      await getNFTOwners();
    }
  };
  await getNFTOwners();
  let owners = {};
  result.forEach((owner) => {
    const { owner_of } = owner;
    owner_of in owners ? (owners[owner_of] += 1) : (owners[owner_of] = 1);
  });
  return owners;
};
// GET TRADES
const getTrades = async () => {
  let offset = 0;
  let totalTrades = 0;
  let totalCalled = 0;
  let result = [];
  const getNFTTrades = async () => {
    const NFTTrades = await Moralis.Web3API.token.getNFTTrades({
      address: shoezContract.address,
      offset,
      limit: 500,
    });
    totalCalled += NFTTrades.result.length;
    result = [...result, ...NFTTrades.result];
    totalTrades === 0 && (totalTrades = NFTTrades.total);
    if (totalTrades > totalCalled) {
      offset += 500;
      await getNFTTrades();
    }
  };
  await getNFTTrades();
  let trades = [];
  result.forEach((trade) => {
    const { buyer_address, seller_address, price } = trade;
    trades.push({
      buyer: buyer_address,
      seller: seller_address,
      price: Moralis.Units.FromWei(price),
    });
  });
  return trades;
};
// GET TRANSFERS
const getTransfers = async () => {
  let offset = 0;
  let totalTransfers = 0;
  let totalCalled = 0;
  let result = [];
  const getContractNFTTransfers = async () => {
    const ContractNFTTransfers =
      await Moralis.Web3API.token.getContractNFTTransfers({
        address: shoezContract.address,
        offset,
        limit: 500,
      });
    totalCalled += ContractNFTTransfers.result.length;
    result = [...result, ...ContractNFTTransfers.result];
    totalTransfers === 0 && (totalTransfers = ContractNFTTransfers.total);
    if (totalTransfers > totalCalled) {
      offset += 500;
      await getContractNFTTransfers();
    }
  };
  await getContractNFTTransfers();
  let transfers = [];
  result.forEach((transfer) => {
    const { to_address, from_address, value } = transfer;
    transfers.push({
      buyer: to_address,
      seller: from_address,
      price: Moralis.Units.FromWei(value),
    });
  });
  return transfers;
};
// TAKE SNAPSHOT
const takeSnapshot = async () => {
  const owners = await getOwners();
  return fetchAPI("take-snapshot", { owners });
};
// READ SNAPSHOT
const readSnapshot = async (id) => {
  return fetchAPI("read-snapshot", { id });
};
// CREATE CSV
const createCSV = async () => {
  const { owners } = await readSnapshot("last");
  let csvArray = [];
  Object.keys(owners).forEach((address) => {
    csvArray.push(`${address} owns ${owners[address]} SHOEz`);
  });
  const csvContent = "data:text/csv;charset=utf-8," + csvArray.join("\n");
  window.open(encodeURI(csvContent));
};
// AIR DROP TICKETS
const airdropTickets = async () => {
  try {
    const trades = await getTrades();
    const transfers = await getTransfers();
    const filteredTx = [...new Set([...trades, ...transfers])].filter(
      (tx) =>
        tx.price < 0.09 &&
        tx.price > 0 &&
        tx.seller !== "0x0000000000000000000000000000000000000000"
    );
    let owners = await getOwners();
    const ownerAddresses = Object.keys(owners);
    filteredTx.forEach((tx) => {
      if (ownerAddresses.includes(tx.buyer)) {
        delete owners[tx.buyer];
      } else if (ownerAddresses.includes(tx.seller)) {
        delete owners[tx.seller];
      }
    });
    const ethereumTowers = "0xbd10cbe79473e039ee3856621c5c83446f111dce";
    owners["0xb04a2D251C790a9442A75f6a733081AAF10c0410"] = 3;
    let addressArray = Object.keys(owners);
    let idsArray = [];
    let amountsArray = Object.values(owners);
    Object.keys(owners).forEach((owner) => {
      if (owner === ethereumTowers) {
        idsArray.push(2);
      } else {
        const randomNumber = Math.random();
        randomNumber < 0.05 ? idsArray.push(1) : idsArray.push(0);
      }
    });

    console.log("addressArray: ", addressArray);
    console.log("idsArray: ", idsArray);
    console.log("amountsArray: ", amountsArray);

    const contract = await connectToContract(
      ticketzContract.address,
      ticketzContract.abi
    );
    await contract.mint(addressArray, idsArray, amountsArray);
    return true;
  } catch (error) {
    emitError("Airdrop Error", error);
    return false;
  }
};
// EXPORTS
export {
  degrees,
  loadTexture,
  emitError,
  fetchAPI,
  connectToWallet,
  connectToContract,
  takeSnapshot,
  readSnapshot,
  createCSV,
  airdropTickets,
};
