// IMPORTS
import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { RoundedBoxGeometry } from "three/examples/jsm/geometries/RoundedBoxGeometry";
import gsap from "gsap";
import colors from "../styles/modules/_exports.module.scss";
import { degrees, loadTexture } from "../helpers/methods";
import { tickets } from "../helpers/variables";
// COMPONENT
const All = () => {
  // STATE
  const mount = useRef(null);
  // LIFE CYCLE
  useEffect(() => {
    // VARIABLES
    let height = window.innerHeight;
    let width = window.innerWidth;
    const raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();
    let clicked = false;
    // TICKETS
    const attendee = {
      id: 0,
      name: "attendee",
      object: new THREE.Group(),
      dropdownFront: new THREE.Group(),
      dropdownBack: new THREE.Group(),
      showDropdown: false,
      video: null,
      videoTexture: null,
      texture1: loadTexture(tickets[0].image),
      texture2: loadTexture(tickets[0].dropdown),
    };
    const vip = {
      id: 1,
      name: "vip",
      object: new THREE.Group(),
      dropdownFront: new THREE.Group(),
      dropdownBack: new THREE.Group(),
      showDropdown: false,
      video: null,
      videoTexture: null,
      texture1: loadTexture(tickets[1].image),
      texture2: loadTexture(tickets[1].dropdown),
    };
    const ethereumTowers = {
      id: 2,
      name: "ethereumTowers",
      object: new THREE.Group(),
      dropdownFront: new THREE.Group(),
      dropdownBack: new THREE.Group(),
      showDropdown: false,
      video: null,
      videoTexture: null,
      texture1: loadTexture(tickets[2].image),
      texture2: loadTexture(tickets[2].dropdown),
    };
    const cryptoHoldem = {
      id: 3,
      name: "cryptoHoldem",
      object: new THREE.Group(),
      dropdownFront: new THREE.Group(),
      dropdownBack: new THREE.Group(),
      showDropdown: false,
      video: null,
      videoTexture: null,
      texture1: loadTexture(tickets[3].image),
      texture2: loadTexture(tickets[3].dropdown),
    };
    const ndax = {
      id: 4,
      name: "ndax",
      object: new THREE.Group(),
      dropdownFront: new THREE.Group(),
      dropdownBack: new THREE.Group(),
      showDropdown: false,
      video: null,
      videoTexture: null,
      texture1: loadTexture(tickets[4].image),
      texture2: loadTexture(tickets[4].dropdown),
    };
    // LOAD TEXTURES
    Promise.all([
      attendee.texture1,
      attendee.texture2,
      vip.texture1,
      vip.texture2,
      ethereumTowers.texture1,
      ethereumTowers.texture2,
      cryptoHoldem.texture1,
      cryptoHoldem.texture2,
      ndax.texture1,
      ndax.texture2,
    ])
      .then((textrues) => {
        // BOX
        const generateBox = (ticket) => {
          const boxGeometry = new RoundedBoxGeometry(17, 17, 0.6, 10, 1);
          const boxMaterial = new THREE.MeshBasicMaterial({
            color: new THREE.Color(tickets[ticket.id].color),
          });
          const boxMesh = new THREE.Mesh(boxGeometry, boxMaterial);
          ticket.object.add(boxMesh);
        };
        // FACE
        const generateFace = (ticket, texture) => {
          const faceGeometry = new THREE.PlaneGeometry(16, 16);
          const faceMaterial = new THREE.MeshBasicMaterial({ map: texture });
          const frontFace = new THREE.Mesh(faceGeometry, faceMaterial);
          frontFace.position.set(0, 0, 0.302);
          const backFace = new THREE.Mesh(faceGeometry, faceMaterial);
          backFace.position.set(0, 0, -0.302);
          backFace.rotateY(degrees(180));
          ticket.object.add(frontFace);
          ticket.object.add(backFace);
        };
        // BUTTON
        const generateButton = (ticket) => {
          const buttonGeometry = new THREE.PlaneGeometry(2, 2);
          const buttonMaterial = new THREE.MeshBasicMaterial({
            color: new THREE.Color(colors.pink),
            transparent: true,
            opacity: 0,
          });
          const playButtonFront = new THREE.Mesh(
            buttonGeometry,
            buttonMaterial
          );
          playButtonFront.name = `${ticket.name}PlayButton`;
          playButtonFront.position.set(6.1, 6.1, 0.304);
          const playButtonBack = new THREE.Mesh(buttonGeometry, buttonMaterial);
          playButtonBack.name = `${ticket.name}PlayButton`;
          playButtonBack.position.set(-6.1, 6.1, -0.304);
          playButtonBack.rotateY(degrees(180));
          ticket.object.add(playButtonFront);
          ticket.object.add(playButtonBack);
        };
        // DROPDOWN
        const generateDropdown = (ticket, texture) => {
          // VIDEO
          ticket.video = document.getElementById("video");
          ticket.videoTexture = new THREE.VideoTexture(ticket.video);
          ticket.videoTexture.minFilter = THREE.LinearFilter;
          ticket.videoTexture.magFilter = THREE.LinearFilter;
          const videoGeometry = new THREE.PlaneGeometry(16, 8.5);
          const videoMaterial = new THREE.MeshBasicMaterial({
            map: ticket.videoTexture,
            side: THREE.FrontSide,
            toneMapped: false,
          });
          const videoMeshFront = new THREE.Mesh(videoGeometry, videoMaterial);
          const videoMeshBack = new THREE.Mesh(videoGeometry, videoMaterial);
          videoMeshFront.position.set(0, 0, 0.002);
          videoMeshBack.position.set(0, 0, 0.002);
          ticket.dropdownFront.add(videoMeshFront);
          ticket.dropdownBack.add(videoMeshBack);
          // UI
          const uiGeometry = new THREE.PlaneGeometry(16, 16);
          const uiMaterial = new THREE.MeshBasicMaterial({
            map: texture,
          });
          ticket.dropdownFront.add(new THREE.Mesh(uiGeometry, uiMaterial));
          ticket.dropdownBack.add(new THREE.Mesh(uiGeometry, uiMaterial));
          // DROP DOWN
          ticket.dropdownFront.position.set(0, 0, 0.303);
          ticket.dropdownFront.visible = false;
          ticket.dropdownBack.position.set(0, 0, -0.303);
          ticket.dropdownBack.rotateY(degrees(180));
          ticket.dropdownBack.visible = false;
          ticket.object.add(ticket.dropdownFront);
          ticket.object.add(ticket.dropdownBack);
        };
        generateBox(attendee);
        generateFace(attendee, textrues[0]);
        generateButton(attendee);
        generateDropdown(attendee, textrues[1]);

        generateBox(vip);
        generateFace(vip, textrues[2]);
        generateButton(vip);
        generateDropdown(vip, textrues[3]);

        generateBox(ethereumTowers);
        generateFace(ethereumTowers, textrues[4]);
        generateButton(ethereumTowers);
        generateDropdown(ethereumTowers, textrues[5]);

        generateBox(cryptoHoldem);
        generateFace(cryptoHoldem, textrues[6]);
        generateButton(cryptoHoldem);
        generateDropdown(cryptoHoldem, textrues[7]);

        generateBox(ndax);
        generateFace(ndax, textrues[8]);
        generateButton(ndax);
        generateDropdown(ndax, textrues[9]);
      })
      .then(() => {
        initialize();
      });
    // INITIALIZE
    const initialize = () => {
      // SCENE
      const scene = new THREE.Scene();
      // LIGHTS
      const ambientLight = new THREE.AmbientLight(colors.gray);
      scene.add(ambientLight);
      // CAMERA
      const camera = new THREE.PerspectiveCamera(
        75,
        width / height,
        0.1,
        10000
      );
      camera.position.set(0, 0, 40);
      scene.add(camera);
      // RENDERER
      const renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
      });
      renderer.setSize(width, height);
      renderer.setPixelRatio(window.devicePixelRatio);
      mount.current.appendChild(renderer.domElement);
      // TICKET
      scene.add(attendee.object);
      scene.add(vip.object);
      scene.add(ethereumTowers.object);
      scene.add(cryptoHoldem.object);
      scene.add(ndax.object);
      attendee.object.position.set(-20, 10, 0);
      vip.object.position.set(-20, -10, 0);
      ethereumTowers.object.position.set(0, 10, 0);
      cryptoHoldem.object.position.set(0, -10, 0);
      ndax.object.position.set(20, 10, 0);
      // ANIMATIONS
      const attendeeSpin = gsap.timeline({ paused: true });
      attendeeSpin.fromTo(
        attendee.object.rotation,
        { y: degrees(0) },
        {
          y: degrees(-180),
          duration: 3,
          repeat: -1,
          ease: "linear",
        }
      );
      const vipSpin = gsap.timeline({ paused: true });
      vipSpin.fromTo(
        vip.object.rotation,
        { y: degrees(0) },
        {
          y: degrees(-180),
          duration: 3,
          repeat: -1,
          ease: "linear",
        }
      );
      const ethereumTowersSpin = gsap.timeline({ paused: true });
      ethereumTowersSpin.fromTo(
        ethereumTowers.object.rotation,
        { y: degrees(0) },
        {
          y: degrees(-180),
          duration: 3,
          repeat: -1,
          ease: "linear",
        }
      );
      const cryptoHoldemSpin = gsap.timeline({ paused: true });
      cryptoHoldemSpin.fromTo(
        cryptoHoldem.object.rotation,
        { y: degrees(0) },
        {
          y: degrees(-180),
          duration: 3,
          repeat: -1,
          ease: "linear",
        }
      );
      const ndaxSpin = gsap.timeline({ paused: true });
      ndaxSpin.fromTo(
        ndax.object.rotation,
        { y: degrees(0) },
        {
          y: degrees(-180),
          duration: 3,
          repeat: -1,
          ease: "linear",
        }
      );
      // HANDLE EVENTS
      const handleEvents = () => {
        window.addEventListener("click", (event) => {
          clicked = true;
          pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
          pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
        });
        window.addEventListener("resize", () => {
          camera.aspect = window.innerWidth / window.innerHeight;
          camera.updateProjectionMatrix();
          renderer.setSize(window.innerWidth, window.innerHeight);
          renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        });
        window.addEventListener("orientationchange", () => {
          camera.aspect = window.innerWidth / window.innerHeight;
          camera.updateProjectionMatrix();
          renderer.setSize(window.innerWidth, window.innerHeight);
          renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        });
      };
      // LOOP
      const loop = () => {
        raycaster.setFromCamera(pointer, camera);
        const intersects = raycaster.intersectObjects(scene.children);
        if (clicked) {
          if (intersects.length > 0) {
            if (intersects[0].object?.name === "attendeePlayButton") {
              clicked = false;
              attendee.showDropdown = !attendee.showDropdown;
              if (attendee.showDropdown) {
                attendeeSpin.pause();
                attendee.video.play();
              } else {
                attendee.video.pause();
                attendeeSpin.play();
              }
              attendee.dropdownFront.visible = attendee.showDropdown;
              attendee.dropdownBack.visible = attendee.showDropdown;
            } else if (intersects[0].object?.name === "vipPlayButton") {
              clicked = false;
              vip.showDropdown = !vip.showDropdown;
              if (vip.showDropdown) {
                vipSpin.pause();
                vip.video.play();
              } else {
                vip.video.pause();
                vipSpin.play();
              }
              vip.dropdownFront.visible = vip.showDropdown;
              vip.dropdownBack.visible = vip.showDropdown;
            } else if (
              intersects[0].object?.name === "ethereumTowersPlayButton"
            ) {
              clicked = false;
              ethereumTowers.showDropdown = !ethereumTowers.showDropdown;
              if (ethereumTowers.showDropdown) {
                ethereumTowersSpin.pause();
                ethereumTowers.video.play();
              } else {
                ethereumTowers.video.pause();
                ethereumTowersSpin.play();
              }
              ethereumTowers.dropdownFront.visible =
                ethereumTowers.showDropdown;
              ethereumTowers.dropdownBack.visible = ethereumTowers.showDropdown;
            } else if (
              intersects[0].object?.name === "cryptoHoldemPlayButton"
            ) {
              clicked = false;
              cryptoHoldem.showDropdown = !cryptoHoldem.showDropdown;
              if (cryptoHoldem.showDropdown) {
                cryptoHoldemSpin.pause();
                cryptoHoldem.video.play();
              } else {
                cryptoHoldem.video.pause();
                cryptoHoldemSpin.play();
              }
              cryptoHoldem.dropdownFront.visible = cryptoHoldem.showDropdown;
              cryptoHoldem.dropdownBack.visible = cryptoHoldem.showDropdown;
            } else if (intersects[0].object?.name === "ndaxPlayButton") {
              clicked = false;
              ndax.showDropdown = !ndax.showDropdown;
              if (ndax.showDropdown) {
                ndaxSpin.pause();
                ndax.video.play();
              } else {
                ndax.video.pause();
                ndaxSpin.play();
              }
              ndax.dropdownFront.visible = ndax.showDropdown;
              ndax.dropdownBack.visible = ndax.showDropdown;
            }
          }
        }
        attendee.videoTexture.update();
        vip.videoTexture.update();
        ethereumTowers.videoTexture.update();
        cryptoHoldem.videoTexture.update();
        ndax.videoTexture.update();
        renderer.render(scene, camera);
      };
      // START
      handleEvents();
      attendeeSpin.play();
      vipSpin.play();
      ethereumTowersSpin.play();
      cryptoHoldemSpin.play();
      ndaxSpin.play();
      renderer.setAnimationLoop(loop);
    };
  }, []);
  // RENDER
  return (
    <div ref={mount} className="allContainer">
      <video
        id="video"
        // muted
        loop
        style={{ display: "none" }}
        src={require("../assets/videos/rockies.mp4")}
      />
    </div>
  );
};
// EXPORT
export default All;
