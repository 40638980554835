// IMPORTS
import React from "react";
// COMPONENT
const Blocked = ({ props }) => {
  // STATE
  const { reason } = props;
  // RENDER
  return (
    <div className="blockedContainer">
      {reason === "404" ? (
        <p className="blockedTitle">404 - This page does not exist.</p>
      ) : (
        reason === "Wrong Address" && (
          <>
            <p className="blockedTitle">This Page is Blocked</p>
            <p className="blockedText">
              Please switch to the correct wallet address.
            </p>
          </>
        )
      )}
    </div>
  );
};
// EXPORT
export default Blocked;
